import { Component, isDevMode } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommunicationService } from './helpers/services';
import { Message } from 'primeng/primeng';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  msgs: Message[] = [];
  growlObj: any = {};

  constructor(public _myCommunicationService: CommunicationService) {
    this._myCommunicationService.changeEmitted$.subscribe(message => {
      this.growlObj = message;
      this.msgs = [];
      this.msgs.push(this.growlObj);
    });
  }
}
