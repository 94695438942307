import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { UtilsService } from '../core/utils.service';
import { DataService } from '../core/interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private utils: UtilsService, private dataService: DataService) { }

  getAllTrainings(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_TRAININGS, req);
  }
  getUserTrainingFeedback(req){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_USER_TRAINING_FEEDBACK, req);
  }
  getAllFeedbackQuestions() {
    const req = {
    }
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_FEEDBACK_TEMPLATE_QUESTION_BY_ORG, req);
  }
  getAllTrainingRequests(req) {
   
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_TRAINING_REQUESTS, req);
  }


}
