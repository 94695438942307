import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { DataService } from '../core/interceptor.service';
import { UtilsService } from '../core/utils.service';

@Injectable()
export class HiringTrackerService {

  constructor(private dataService: DataService, private utils: UtilsService) { }

  getAllHiringTracker() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_HIRING_TRACKER, req);
  }

  addHiringTracker(addHiring) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_HIRING_TRACKER, addHiring);
  }

  updateHiringTracker(updateHiring) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_HIRING_TRACKER, updateHiring);
  }

  deleteHiringTracker(id) {
    const req = {
      id: id
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_HIRING_TRACKER, req);
  }

  addCandidate(obj) {
      
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_CANDIDATE, obj);

  }

  updateCandidate(obj) {
      return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_CANDIDATE, obj);
  }

  getAllCandidates(obj) {
      
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_CANDIDATES, obj);
  }

  deleteCandidate(obj) {
      
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_CANDIDATE, obj);
  }
 



}
