import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { KRONOS } from '../../../app-constants';
import * as Crypto from 'crypto-js';
import { AuthenticationService } from '..';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import { Message, ConfirmationService } from 'primeng/primeng';
import { CookieService } from 'ngx-cookie';
import { CommunicationService } from './communication.service';
import alasql from 'alasql';
import * as XLSX from 'xlsx';
import * as moment from 'moment';

declare var $: any;
/**
 * The Utils Service possess the common methods to be used across the system
 */

@Injectable()
export class UtilsService {
    sessionId: any = {};
    growlData: any = {};
    loggedInUser: any;
    stringValue: string;
    key = Crypto.enc.Hex.parse('36ebe205bcdfc499a25e6923f4450fa8');
    iv = Crypto.enc.Hex.parse('be410fea41df7162a679875ec131cf2c');
    numValue: number;
    /**
      * Returns the true or false based on the provided api response's status code
      * @return boolean
      */
    constructor(private http: HttpClient, private router: Router, private route: ActivatedRoute,
        private _cookieService: CookieService, private _myCommunicationService: CommunicationService) { }

    isResponseSuccess(apiResponse): boolean {
        let is_success: boolean = false;
        switch (apiResponse.statusCode) {
            case 200:
                if (apiResponse.statusMessage == "Success") {
                    is_success = true;
                }
                break;
            case 390:
                is_success = false;
                break;
            case 306:
                is_success = false;
                break;
            case 401:
                is_success = false;
                this.appLogout();
                break;
            case 444:
                is_success = false;
                this.messageToaster('warn', KRONOS.MESSAGES.UNAUTHORIZED_ACCESS);
                break;

        }
        return is_success;
    }

    passwordEncryption(originalValue) {
        this.stringValue = originalValue;
        const encrypted = Crypto.AES.encrypt(
            this.stringValue.toString(),
            this.key,
            {
                iv: this.iv,
                mode: Crypto.mode.CBC,
                padding: Crypto.pad.Pkcs7
            });
        return encrypted.toString();
    }

    passwordDecryption(encriptedValue) {
        const manual_data = encriptedValue;
        const decrypted = Crypto.AES.decrypt(
            manual_data,
            this.key,
            {
                iv: this.iv,
                mode: Crypto.mode.CBC,
                padding: Crypto.pad.Pkcs7
            }
        );
        return decrypted.toString(Crypto.enc.Utf8);

    }

    idEncryption(number) {
        this.numValue = number;
        const encrypted = Crypto.AES.encrypt(
            this.numValue.toString(),
            this.key,
            {
                iv: this.iv,
                mode: Crypto.mode.CBC,
                padding: Crypto.pad.Pkcs7
            }
        );
        return encrypted;
    }

    idDecryption(encrypted) {
        let manual_data = encrypted;
        const decrypted = Crypto.AES.decrypt(
            manual_data,
            this.key,
            {
                iv: this.iv,
                mode: Crypto.mode.CBC,
                padding: Crypto.pad.Pkcs7
            }
        );
        manual_data = decrypted.toString(Crypto.enc.Utf8);
        // var numberConversion = parseInt(manual_data);
        return manual_data;
    }

    getSessionId(): any {
        if (this._cookieService.get('loggedInUser') !== undefined) {
            this.sessionId = this._cookieService.get('loggedInUser');
            this.sessionId = JSON.parse(this.sessionId);
            return this.sessionId.sessionId;
        }
    }

    getLoggedInUser(): any {
        if (this._cookieService.get('loggedInUser') !== undefined) {
            let loggedInUser = this._cookieService.get('loggedInUser');
            loggedInUser = JSON.parse(loggedInUser);
            return loggedInUser;
        }
    }

    getHoursTimeOnly(minutes): String {
        return Math.floor(minutes / 60).toString();
    }

    getMinutesTimeOnly(minutes): String {
        return (minutes % 60).toString();
    }

    convertHoursToMins(hours): number {
        if (typeof hours != 'undefined' && hours) {
            return (Number(hours) * 60);
        }
        return 0;
    }

    /*Get color Hex code by passing a string*/
    stringToColor(str) {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let hue = Math.abs(hash % 360);
        let l = Math.abs(hash % 35) + 15;
        return this.HSLToHex(hue, '100', l);
    }

    HSLToHex(h, s, l) {
        s /= 100;
        l /= 100;

        let c = (1 - Math.abs(2 * l - 1)) * s,
            x = c * (1 - Math.abs((h / 60) % 2 - 1)),
            m = l - c / 2, r, g, b;

        if (0 <= h && h < 60) {
            r = c; g = x; b = 0;
        } else if (60 <= h && h < 120) {
            r = x; g = c; b = 0;
        } else if (120 <= h && h < 180) {
            r = 0; g = c; b = x;
        } else if (180 <= h && h < 240) {
            r = 0; g = x; b = c;
        } else if (240 <= h && h < 300) {
            r = x; g = 0; b = c;
        } else if (300 <= h && h < 360) {
            r = c; g = 0; b = x;
        }
        // Having obtained RGB, convert channels to hex
        r = Math.round((r + m) * 255).toString(16);
        g = Math.round((g + m) * 255).toString(16);
        b = Math.round((b + m) * 255).toString(16);

        // Prepend 0s, if necessary
        if (r.length == 1)
            r = "0" + r;
        if (g.length == 1)
            g = "0" + g;
        if (b.length == 1)
            b = "0" + b;

        return "#" + r + g + b;
    }

    /*Get color Hex code by passing a string*/
    hashCode(str) { // java String#hashCode
        var hash = 0;
        for (var i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    intToRGB(i) {
        var c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();

        return '#' + '00000'.substring(0, 6 - c.length) + c;
    }

    /*Get text contrast by passing background hex color*/
    getContrast() {
        return '#ffffff';
    }


    minutesToStr(minutes) {
        var sign = '';
        if (minutes < 0) {
            sign = '-';
        }

        var hours = this.leftPad(Math.floor(Math.abs(minutes) / 60));
        var minute = this.leftPad(Math.abs(minutes) % 60);
        return sign + hours + "." + minute;

    }

    leftPad(number) {
        return ((number < 10 && number >= 0) ? '0' : '') + number;
    }

    /* Function to generate UUID */
    generateUUID() {
        var d = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }

    /* Function to return UUID along with function parameter */
    generateUniqueid(data) {
        var uuid = this.generateUUID();
        return data + '#' + uuid;
    }

    splitLine(data) {
        var newString = data.replace(/↵/g, "<br/>");
        return newString;
    }

    materialInput() {
        $("input.floating-input").each(function () {
            var $input = $(this).val();
            if ($input) {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
            } else {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({
                    'color': '#999', 'font-size': '17px', ' font-weight': 'normal', 'position': 'absolute',
                    'pointer-events': 'none', 'left': '5px', 'top': '5px', 'transition': '0.2s ease all', '-moz-transition': '0.2s ease all', '-webkit-transition': '0.2s ease all'
                })
            }
        })

        $("input.floating-input").click(function () {
            $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
        })

        $("input.floating-input").blur(function () {
            var $input = $(this).val();
            if ($input) {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
            } else {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({
                    'color': '#999', 'font-size': '17px', ' font-weight': 'normal', 'position': 'absolute',
                    'pointer-events': 'none', 'left': '5px', 'top': '5px', 'transition': '0.2s ease all', '-moz-transition': '0.2s ease all', '-webkit-transition': '0.2s ease all'
                })
            }
        })

        $("input.floating-input").on('change', function () {
            var $input = $(this).val();
            if ($input) {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
            } else {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({
                    'color': '#999', 'font-size': '17px', ' font-weight': 'normal', 'position': 'absolute',
                    'pointer-events': 'none', 'left': '5px', 'top': '5px', 'transition': '0.2s ease all', '-moz-transition': '0.2s ease all', '-webkit-transition': '0.2s ease all'
                })
            }
        })

        $("input.floating-input").on('keypress', function () {
            var $input = $(this).val();
            if ($input) {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({ 'top': '-25px', 'font-size': '15px', 'color': '#0C395F' })
            } else {
                $(this).parent(".ngui-auto-complete-wrapper").siblings("label").css({
                    'color': '#999', 'font-size': '17px', ' font-weight': 'normal', 'position': 'absolute',
                    'pointer-events': 'none', 'left': '5px', 'top': '5px', 'transition': '0.2s ease all', '-moz-transition': '0.2s ease all', '-webkit-transition': '0.2s ease all'
                })
            }
        })
    }

    // applogout() {
    //     let headers = new Headers({ 'Content-Type': 'application/json' });
    //     let options = new RequestOptions({ headers: headers });
    //     if (this._cookieService.get('loggedInUser') != undefined) {
    //         this._cookieService.remove('loggedInUser');
    //         this.growlData = {
    //             severity: 'warn', summary: KRONOS.MESSAGES.SESSION_EXPIRED, detail: ''
    //         };
    //         this._myCommunicationService.emitChange(this.growlData);
    //         this.router.navigate([KRONOS.ROUTERLINKS.LOGIN]);
    //     }
    // }

    messageToaster(status, message) {
        this.growlData = {
            severity: status, summary: message, detail: ''
        };
        this._myCommunicationService.emitChange(this.growlData);
    }

    appLogout() {
        if (this._cookieService.get('loggedInUser') !== undefined) {
            this._cookieService.remove('loggedInUser');
            this._cookieService.remove('Certificate');
            localStorage.removeItem('UserDirectoryFilters');
            localStorage.removeItem('reportFilters');
            this.growlData = {
                severity: 'warn', summary: KRONOS.MESSAGES.SESSION_EXPIRED, detail: ''
            };
            this._myCommunicationService.emitChange(this.growlData);
            this.router.navigate([KRONOS.ROUTERLINKS.LOGIN]);
        }
    }



    validatorMessage(apiResponse, message): boolean {
        let is_success: boolean = true;
        switch (apiResponse.statusCode) {
            case 200:
                if (apiResponse.statusMessage == "Success") {
                    is_success = true;
                    this.growlData = {
                        severity: 'success', summary: message, detail: ''
                    };
                    this._myCommunicationService.emitChange(this.growlData);
                }
                break;
            case 320:
                is_success = false;
                if (apiResponse.errorMessage == "email already exist") {
                    this.growlData = {
                        severity: 'error', summary: apiResponse.errorMessage, detail: ''
                    };
                } else {
                    this.growlData = {
                        severity: 'error', summary: apiResponse.statusMessage, detail: ''
                    };
                }
                this._myCommunicationService.emitChange(this.growlData);
                break;
            case 401:
                is_success = false;
                this.growlData = {
                    severity: 'error', summary: apiResponse.statusMessage, detail: ''
                };
                this._myCommunicationService.emitChange(this.growlData);
                break;
            case 300:
                is_success = false;
                this.growlData = {
                    severity: 'error', summary: apiResponse.errorMessage, detail: ''
                };
                this._myCommunicationService.emitChange(this.growlData);
                break;
            case 301:
            case 304:
            case 322:
            case 311:
            case 312:
                is_success = false;
                this.growlData = {
                    severity: 'error', summary: apiResponse.statusMessage, detail: ''
                };
                this._myCommunicationService.emitChange(this.growlData);
                break;
            case 400:
            case 345:
            case 348:
            case 354:
            case 355:
            case 364:
            case 365:
            case 369:
            case 370:
            case 371:
            case 372:
            case 373:
            case 374:
            case 375:
            case 376:
            case 377:
            case 378:
            case 379:
            case 380:
            case 383:
            case 384:
            case 385:
            case 386:
            case 387:
            case 389:
            case 390:
                is_success = false;
                break;
            case 306:
                is_success = false;
                this.appLogout();
                break;
           
        }
        return is_success;
    }

    routeToContextUrls(url) {
        switch (url) {
            case 'root': this.router.navigate([KRONOS.ROUTERLINKS.ROOT]);
                break;
            case 'travel': this.router.navigate([KRONOS.ROUTERLINKS.TRAVEL]);
                break;
            case 'add-travel': this.router.navigate([KRONOS.ROUTERLINKS.ADD_TRAVEL]);
                break;
            case 'view-travel': this.router.navigate([KRONOS.ROUTERLINKS.VIEW_TRAVEL]);
                break;
            case 'update-travel': this.router.navigate([KRONOS.ROUTERLINKS.UPDATE_TRAVEL]);
                break;
            case 'notice': this.router.navigate([KRONOS.ROUTERLINKS.NOTICE]);
                break;
            case 'reports': this.router.navigate([KRONOS.ROUTERLINKS.REPORTS]);
                break; 
            case 'training-report': this.router.navigate([KRONOS.ROUTERLINKS.TRAINING_REPORT]);
                break; 
            case 'trainer-report': this.router.navigate([KRONOS.ROUTERLINKS.TRAINER_REPORT]);
                break;        

            default: this.router.navigate([KRONOS.ROUTERLINKS.ROOT]);
        }
    }

    // Report Export
    exportData(cols, fileName, filebody) {
        cols = JSON.parse(JSON.stringify(cols));
        let alasql1;
        let reportStyle = [{
            sheetid: fileName,
            headers: true,
            column: { style: { Font: { Bold: '1' } } },
            rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
            cells: {
                1: {
                    1: {
                        style: { Font: { Color: '#00FFFF' } }
                    }
                }
            }
        }];
        if (cols && cols.length > 0 && fileName && filebody) {
            alasql1 = cols[0].field + ' AS ' + cols[0].header.replace(/\s/g, '') + ' , ';
            for (let i = 1; i < cols.length; i++) {
                cols[i].header = cols[i].header.replace(/\s/g, '');
            }
            for (let i = 1; i < cols.length - 1; i++) {
                alasql1 = alasql1 + cols[i].field + ' AS ' + cols[i].header.replace(/\s/g, '') + ' , ';
            }
            alasql1 = alasql1 + cols[cols.length - 1].field + ' AS ' + cols[cols.length - 1].header.replace(/\s/g, '');
            alasql['utils'].isBrowserify = false;
            alasql['utils'].global.XLSX = XLSX;
            alasql('SELECT ' + alasql1 + ' INTO XLSX("' + fileName + ' Report.xlsx",?) FROM ?', [reportStyle, [filebody]]);
        }
    }

    reportHeader(selectedFilter, filter) {
        const cols = [];
        filter.forEach(obj => {
            if (selectedFilter.includes(obj.header)) {
                cols.push(obj);
            }
        });
        return cols;
    }
    //Training Feedback Report Export
    exportFeedback(sheet1) {
        let reportStyle = [{
            sheetid: "Training Report",
            headers: true,
            column: { style: { Font: { Bold: '1' } } },
            rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
            cells: {
                1: {
                    1: {
                        style: { Font: { Color: '#00FFFF' } }
                    }
                }
            }
        // }, {
        //     sheetid: "Feedback Report",
        //     headers: true,
        //     column: { style: { Font: { Bold: '1' } } },
        //     rows: { 1: { style: { Font: { Color: '#FF0077' } } } },
        //     cells: {
        //         1: {
        //             1: {
        //                 style: { Font: { Color: '#00FFFF' } }
        //             }
        //         }
        //     }
        }];
        if (sheet1) {
            alasql['utils'].isBrowserify = false;
            alasql['utils'].global.XLSX = XLSX;
            alasql('SELECT * INTO XLSX("Training Feedback-Report.xlsx",?) FROM ?', [reportStyle, [sheet1]]);
        }
    }

    localConvertor(utcTime) {
        if (utcTime !== null) {
            return moment.utc(utcTime).local().format('YYYY-MM-DD hh:mm A');
        }
    }



}
