import { environment } from '../environments/environment';

export const KRONOS = {

    // Base url from which the application is rendered
    BASE: environment.apiUrl,
    BASE1: environment.apiUrl1,

    USER: 'user/',
    ADMIN: 'admin/',
    HR_URL: 'hr/',
    ANALYTICS: 'analytics/',
    CHALLENGE: 'kronosChallenge/',

    // Role Id's
    SUPER_ADMIN: 5,
    HR: 6,

    /*  Apis are made as constants and are loaded to the services,
        import the constant file into the services and then call it like -
        For API calls - KRONOS.BASE_URL + KRONOS.USER + KRONOS.APIS.LOGIN
        For messages - KRONOS.MESSAGES.USERNAME_REQUIRED
        For constants - KRONOS.CONSTANTS.CONSTANTS_NAME
    */
    APIS: {
        LOGIN: 'login',
        LOGOUT: 'logout',
        FORGOT_PASSWORD: 'forgotPassword',
        CHANGE_PASSWORD: 'change-password',
        GET_ROLES_BY_ORG: 'getRolesByOrg',
        GET_ALL_ORG: 'getAllOrg',
        GET_VERSION: 'getVersion',

        // User-update
        GET_ALL_USERS: 'getAllUsersCompleteData',
        GET_USER_BY_ORG: 'getAllUsersByOrg',
        GET_USER_BY_ID: 'getUserProfile',
        GET_ALL_SITES: 'getAllSiteByOrg',
        GET_ALL_BUSINESS_UNIT: 'getBusinessUnitByOrg',
        GET_ALL_TEAMS: 'getAllTeamByOrg',
        GET_ALL_COUNTRIES: 'getAllCountry',
        GET_ALL_DESIGNATIONS: 'getDesignationByOrg',
        UPDATE_USER: 'updateUserDetails',
        UPDATE_USER_DETAILS: 'updateOtherUserDetails',
        ADD_GOAL: 'addGoals',
        GET_ALL_GOAL: 'getGoals',
        UPDATE_GOAL: 'updateGoals',
        DELETE_GOAL: 'deleteGoals',
        ADD_SECTION: 'addSection',
        UPDATE_SECTION: 'updateSection',
        ADD_TEMPLATE: 'addGoalTemplate',
        UPDATE_TEMPLATE: 'updateGoalTemplate',
        DELETE_TEMPLATE: 'deleteGoalTemplate',
        GET_ALL_GOAL_TEMPLATE: 'getGoalTemplate',
        ADD_TEMPLATE_USER: 'addUserGoal',
        GET_USER_TEMPLATE: 'getUserGoal',
        DELETE_USER_GOAL: 'deleteUserGoal',


        // Hiring-Tracker Update
        GET_ALL_HIRING_TRACKER: 'getAllHiringTracker',
        ADD_HIRING_TRACKER: 'addHiringTracker',
        UPDATE_HIRING_TRACKER: 'updateHiringTracker',
        DELETE_HIRING_TRACKER: 'deleteHiringTracker',

        // Hiring
        ADD_CANDIDATE: 'addCandidate',
        UPDATE_CANDIDATE: 'updateCandidate',
        GET_ALL_CANDIDATES: 'getAllCandidates',
        DELETE_CANDIDATE: 'deleteCandidate',

        // Notice
        ADD_MEMBER_ON_NOTICE_PERIOD: 'addMemberOnNoticePeriod',
        UPDATE_MEMBER_ON_NOTICE_PERIOD: 'updateMemberOnNoticePeriod',
        DELETE_MEMBER_ON_NOTICE_PERIOD: 'deleteMemberOnNoticePeriod',
        GET_ALL_MEMBERS_ON_NOTICE_PERIOD: 'getAllMembersOnNoticePeriod',
        GET_MEMBER_ON_NOTICE_PERIOD_BY_ID: 'getMemberOnNoticePeriodByUserId',


        // analytics
        GET_TRAINING_ANALYTICS: 'getTotalNumberAndTimeSpentOnTrainingsUnitWise',
        GET_LEAVE_ANALYTICS: 'getCountOfDifferentTypesOfLeavesUnitWise',
        GET_ALL_ORG_LEAVES: 'getAllTypesOfLeaveByOrgWise',
        GET_UNITWISE_EXPERIENCE_COUNT: 'getUnitWiseExperienceCount',
        GET_DEPARTMENTWISE_GROWTH: 'getDepartmentWiseGrowth',
        GET_MONTHWISE_DOJ: 'getMonthWiseDoj',
        GET_MONTHWISE_DOL: 'getMonthWiseDol',

        // Reports
        GET_ALL_TRAININGS: 'getAllTrainings',
        GET_USER_TRAINING_FEEDBACK: 'getUserTrainingFeedback',
        GET_FEEDBACK_TEMPLATE_QUESTION_BY_ORG: 'getFeedbackTemplateQuestionByOrg',
        GET_ALL_TRAINING_REQUESTS: 'getAllTrainingRequest',

        // Probation
        GET_ALL_MEMBERS_ON_PROBATION: 'getMembersOnProbation',
        CONFIRM_MEMBERS_ON_PROBATION: 'confirmMembersOnProbation',
        EXTEND_PROBATION_DATE: 'extendProbation',

        // Challenge

        GET_ALL_CHALLENGES: 'getAllChallenges',
        ADD_CHALLENGE: 'addChallenge',
        DELETE_CHALLENGE: 'deleteChallenge',
        UPDATE_CHALLENGE: 'updateChallenge',
        GET_ALL_USER: 'getAllUserByCompanyWithWeb',
        FILE_UPLOAD: 'addBulkUsersForChallenge',

        // Travel
        ADD_NEW_TRAVEL: 'addUserTravelDetails',
        GET_USER_TRAVEL_BASIC_DETAILS: 'getUserTravelBasicDetails',
        DELETE_USER_TRAVEL_DETAILS: 'deleteUserTravelDetails',
        UPDATE_USER_TRAVEL_DETAILS: 'updateUserTravelDetails',
        VIEW_USER_TRAVEL_DETAILS: 'viewUserTravelDetails',

        //TimeReport
        GET_PROJECT_TASK: "getAllProjectTask",
        ADD_BULK_TIME_ENTRY: 'addBulkTimeEntry',
        GET_BULK_LEAVE_ENTRIES: 'getBulkLeaveEntries'
    },

    CONSTANTS: {
    },

    ROUTERLINKS: {
        ROOT: '',
        LOGIN: 'login',
        FORGOT_PASSWORD: 'forgot-password',
        CHANGE_PASSWORD: 'change-password',
        USERS: 'employee',
        HIRING: 'hiring',
        TRACKER: 'tracker',
        PMS: 'pms',
        TRAVEL: 'travel',
        ADD_TRAVEL: 'travel/add-travel',
        UPDATE_TRAVEL: 'travel/update-travel',
        NOTICE: 'notice',
        TRAINING: 'analytics/training',
        LEAVE: 'analytics/leave',
        LEAVE_RECONCILITION: 'leave-reconciliation',
        EXPERIENCE: 'analytics/experience',
        REPORTS: 'reports',
        GROWTH: 'analytics/growth',
        PROBATION: 'probation',
        BATCH_UPLOAD: 'user-upload',
        CHALLENGE: 'challenge',
        VIEW_TRAVEL: 'travel/view-travel',
        VIEW_MEMBER: 'notice/view-member',
        LEAVE_ENTRY: 'leave-entry',
        TRAINING_REPORT: 'reports/training-report',
        TRAINER_REPORT: 'reports/trainer-report',
        USER_TRAININGS_REPORT: 'reports/user-trainings-report',
        PARTICIPANTS_REPORT: 'reports/participants-report',
        TRAINING_REQUESTS_REPORT: 'reports/training-requests-report',
        TRAINING_FEEDBACK_REPORT:'reports/training-feedback-report',
    },

    URL: {
    },

    VALIDATOR_MESSAGES: {
    },

    MESSAGES: {
        INVALID_CREDENTIALS: 'Invalid Credentials. Please Try Again',
        SESSION_EXPIRED: 'Session expired',
        TRY_AGAIN_LATER: 'please try again Later...',
        PLEASE_LOGIN: 'Please login again.',
        LOGOUT_SUCCESSFUL: 'User logged out successfully',
        ERROR: 'Oops! Something Went Wrong',
        SOMETHING_WRONG: 'Oops! Something went wrong',
        TRY_AGAIN: 'Please try again',
        UNAUTHORIZED_CREDENTIALS: 'Unauthorized Credentials',
        UPDATE_USER_SUCCESS: 'User Updated Successfully',
        ADD_GOAL_SUCCESS: 'Goal added successfully',
        UPDATE_GOAL_SUCCESS: 'Goal updated successfully',
        DELETE_GOAL_SUCCESS: 'Goal deleted successfully',
        ADD_SECTION_SUCCESS: 'Section added successfully',
        UPDATE_SECTION_SUCCESS: 'Section updated successfully',
        ADD_TEMPLATE_SUCCESS: 'Template added successfully',
        ADD_TEMPLATE_USER_SUCCESS: 'Template added to user successfully',
        UPDATE_TEMPLATE_SUCCESS: 'Template updated successfully',
        DELETE_TEMPLATE_SUCCESS: 'Template deleted successfully',
        ADD_HIRING_TRACKER_SUCCESS: 'Added successfully',
        UPDATE_HIRING_TRACKER_SUCCESS: 'Updated successfully',
        DELETE_HIRING_TRACKER_SUCESS: 'Deleted sucessfully',
        ADD_CANDIDATE: 'Candidate added successfully',
        DELETE_CANDIDATE: 'Candidate deleted sucessfully',
        UPDATE_CANDIDATE: 'Candidate updated sucessfully',
        CONFIRMATION_SUCCESS: 'Confirmed sucessfully',
        EXTEND_SUCCESS: 'Extended sucessfully',
        ADD_CHALLENGE: 'Challenge added successfully',
        DELETE_CHALLENGE: 'Challenge deleted sucessfully',
        UPDATE_CHALLENGE: 'Challenge updated successfully',
        FILE_UPLOAD_SUCCESSFULLY: 'File uploaded successfully',
        ADD_NEW_TRAVEL_SUCCESS: 'Travel details added successfully',
        DELETE_TRAVEL_SUCCESS: 'Travel deleted successfully',
        UPDATE_TRAVEL_SUCCESS: 'User details updated successfully',
        ADD_MEMBER_ON_NOTICE: 'Member added successfully',
        UPDATE_MEMBER_ON_NOTICE: 'Member updated successfully',
        UPDATED_SUCCESSFULLY: 'Updated Successfully',
        PROCESS_FAILED: 'Process Failed',
        UNAUTHORIZED_ACCESS: 'Unauthorized Access',
        DOWNLOAD_TRAINING_REPORT: 'Download Training Report',
        DOWNLOAD_TRAINERS_REPORT:'Download Trainer Report',
        DOWNLOAD_USER_TRAININGS_REPORT:'Download User Trainings Report',
        DOWNLOAD_PARTICIPANTS_REPORT:'Download Participants Report',
        DOWNLOAD_TRAINING_REQUESTS_REPORT:'Download Training Requests Report',
        DOWNLOAD_REPORT:"Download Report",
        
        

    }
};

export const STYRIA = {
    'GATEWAY': environment.styria.gateway,

    'INTEGRATION_SERVICE_VER': environment.styria.integrationServiceVersion,

    'ZOHO_PEOPLE': "zohoPeople/",
    'KRONOS': "kronos/",

    APIS: {
        INTEGRATION_SERVICE: {
            'ORG_LEAVE_REPORT': 'orgWiseLeaveCount',
        }
    }
};


