import { Injectable } from '@angular/core';
// import { Observable } from 'rxjs/Observable';
// import 'rxjs/add/operator/map';
import { KRONOS } from '../../../app-constants';
import { UtilsService } from '../core/utils.service';
// import { Subject } from 'rxjs/Subject';
import { DataService } from '../core/interceptor.service';
@Injectable()
export class PmsService {

  constructor(private utils: UtilsService, private dataService: DataService) { }

  addGoal(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_GOAL, req);
  }
  getAllGoal() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_GOAL, req);
  }
  updateGoal(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_GOAL, req);
  }
  deleteGoal(id) {
    const req = { 
      id: id
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_GOAL, req);
  }
  addSection(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_SECTION, req);
  }
  updateSection(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_SECTION, req);
  }
  addTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_TEMPLATE, req);
  }
  updateTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_TEMPLATE, req);
  }
  deleteGoalTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_TEMPLATE, req);
  }
  getAllGoalTEmplate() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_GOAL_TEMPLATE, req);
  }
  addTemplateToUser(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_TEMPLATE_USER, req);
  }
  getUserTemplate(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_USER_TEMPLATE, req);
  }
  deleteUserGoal(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_USER_GOAL, req);
  }
}
