import { Injectable, Injector } from '@angular/core'; 
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';
import { RequestOptions, RequestMethod } from '@angular/http';
import { CommunicationService } from '../../../helpers/services/core/communication.service';
import { UtilsService } from '../../../helpers/services/core/utils.service';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, finalize } from 'rxjs/operators';

@Injectable()
export class DataService {
  constructor(private http: HttpClient, private _myCommunicationService: CommunicationService) { }

  // get method
  get(url: string, params?: any) {
    this.requestCall();
    const options = { params: params || {} };
    
    return this.http.get(url, options).pipe(
      catchError((error) => {
        this.responseCall();
        return throwError(error);
      }),
      finalize(() => this.responseCall())
    );
  }

  // post method
  post(url, req) {
    this.requestCall();
    return this.http.post(url, req).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  // put method
  put(url, req) {
    this.requestCall();
    return this.http.put(url, JSON.stringify(req)).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  // delete method
  delete(url, req) {
    this.requestCall();
    return this.http.put(url, JSON.stringify(req)).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      finalize(() => this.responseCall()));
  }

  requestCall() {
    this._myCommunicationService.loaderDataEmitChange(true);
  }
  responseCall() {
    this._myCommunicationService.loaderDataEmitChange(false);
  }

}

@Injectable()
export class InterceptorService implements HttpInterceptor {


  constructor(private util: UtilsService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const loggedInUser: any = this.util.getLoggedInUser();

    let authReq;
    if (loggedInUser !== undefined) {
      authReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
          .set('Authorization', loggedInUser.sessionId)
      });
    } else {
      authReq = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
          .set('Accept', 'application/json')
      });
    }



    // send the newly created request
    return next.handle(authReq).pipe(catchError(err => {
      if (err.status === 401) {
        // auto logout if 401 response returned from api
        this.util.appLogout();
        location.reload(true);
      };
      const error = err.error.message || err.statusText;
      return throwError(error);
    }));




  }
}
