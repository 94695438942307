import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { UtilsService } from '../core/utils.service';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class TravelsService {

  constructor(private utils: UtilsService, private dataService: DataService) { }
  addUserTravelDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_NEW_TRAVEL, req);
  }
  deleteUserTravelDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_USER_TRAVEL_DETAILS, req);
  }
  viewUserTravelDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.VIEW_USER_TRAVEL_DETAILS, req);
  }
  updateUserTravelDetails(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_USER_TRAVEL_DETAILS, req);
  }
  getUserBasicTravelDetails() {
    const req = { };
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_USER_TRAVEL_BASIC_DETAILS, req);
  }
}
