import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { UtilsService } from '../core/utils.service';
import { Observable, Subject } from 'rxjs';
import { DataService } from '../core/interceptor.service';

@Injectable()
export class UsersService {

  private object = new Subject<any>();
  recieveProfile$ = this.object.asObservable();
  userList: any = [];
  userSkillList: any[];
  userProfile(user: any) {
    this.object.next(user);
  }

  constructor(private utils: UtilsService, private dataService: DataService) { }

  getAllUsers() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_USERS, req);
  }
  /*
* API is used to get user details based on the id
*/
  getUserById(id) {
    const req = {
      userId: id
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_USER_BY_ID, req);
  }

  update(user) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_USER, user);
  }

  updateUserProfile(user) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_USER_DETAILS, user);
  }

  getAllBusinessUnit() {
    const req = {};

    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_BUSINESS_UNIT, req);
  }

  getAllTeam() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_TEAMS, req);
  }

  getAllRoles() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ROLES_BY_ORG, req);
  }

  getAllSites() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_SITES, req);
  }

  getAllCountry() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_COUNTRIES, req);
  }

  getAllDesignations() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_DESIGNATIONS, req);
  }

  getAllProjectTask() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_PROJECT_TASK, req);
  }

  getAllUserByOrg() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_USER_BY_ORG, req);
  }

  addBulkTimeEntry(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.HR_URL + KRONOS.APIS.ADD_BULK_TIME_ENTRY, req);
  }

  getBulkLeaveEntries() {
    return this.dataService.get(KRONOS.BASE + KRONOS.HR_URL + KRONOS.APIS.GET_BULK_LEAVE_ENTRIES);
  }

}
