import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { DataService } from '../core/interceptor.service';
import { UtilsService } from '../core/utils.service';
@Injectable()
export class ProbationService {

  constructor(private dataService: DataService, private utils: UtilsService) { }
  /**
   * Requesting to get members on probation
   */
  getMemberOnProbation() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_MEMBERS_ON_PROBATION, req);
  }
  /**
   * @param  {object} req
   */
  confirmation(id) {
    const req = {
      id: id
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.CONFIRM_MEMBERS_ON_PROBATION, req);
  }

  /**
   * author:Sathish Manickavel
   * @param  {object} req
   */
  extension(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.EXTEND_PROBATION_DATE, req);
  }
}

