import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilterPipe, OrderBy } from '../../helpers/directives';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { SubNavigationComponent } from './sub-navigation/sub-navigation.component';
import { LoaderComponent } from './loader/loader.component';
import { DataService, InterceptorService, UtilsService } from '../../helpers/services';
import { CookieService } from 'ngx-cookie';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ReactiveFormsModule, FormGroup, FormsModule } from '@angular/forms';
import { DragDropModule, ConfirmDialogModule, GrowlModule } from 'primeng/primeng';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ChipsModule } from 'primeng/chips';


import { ChartModule } from 'primeng/primeng';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    DragDropModule,
    ConfirmDialogModule,
    BsDatepickerModule.forRoot(),
    GrowlModule,
    ChipsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartModule
  ],
  declarations: [
    FilterPipe,
    OrderBy,
    TopNavigationComponent,
    SubNavigationComponent,
    LoaderComponent,
  ],
  providers: [ CookieService, UtilsService, DataService, {
    provide: HTTP_INTERCEPTORS,
    useClass: InterceptorService,
    multi: true
  }],
  exports: [
    FilterPipe, OrderBy, TopNavigationComponent, SubNavigationComponent, LoaderComponent, BsDatepickerModule,
     ChipsModule, ChartModule

  ]
})
export class SharedModule { }
