import { Component, OnInit, ViewChild, HostListener, ElementRef, } from '@angular/core';
import { GlobalEventsManager } from '../../../helpers/guards';
import { Router, ActivatedRoute, Event, NavigationEnd } from '@angular/router';
import { KRONOS } from '../../../app-constants';
import { AuthenticationService, UtilsService, CommunicationService } from '../../../helpers/services';
import { CookieService } from 'ngx-cookie';
import { Message } from 'primeng/primeng';
declare var $: any;

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
  providers: [AuthenticationService, GlobalEventsManager]
})
export class TopNavigationComponent implements OnInit {
  showNavBar: boolean = false;
  loggedInUser: any = this.utils.getLoggedInUser();
  growlData: any;
  imageName: string;

  @ViewChild('body') patientDropDownList: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth <= 992) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('toggle');
    }
  }
  constructor(
    private authenticationService: AuthenticationService,
    private globalEventsManager: GlobalEventsManager,
    private route: ActivatedRoute,
    private router: Router,
    private _cookieService: CookieService,
    private _myCommunicationService: CommunicationService,
    public utils: UtilsService) {
    this._myCommunicationService.loggedInDataChangeEmitted$.subscribe(myMessage => {
      this.loggedInUser = JSON.parse(myMessage);
      if (this.loggedInUser) {
        const name = this.loggedInUser.firstName + ' ' + this.loggedInUser.lastName;
        const initials = name.match(/\b\w/g) || [];
        const initial = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        this.imageName = initial;
      }
    });

    if (this._cookieService.get('loggedInUser') != undefined) {
      this.loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'))
      this.globalEventsManager.showNavBarEmitter.subscribe((mode) => {
        if (mode !== null) {
          this.showNavBar = mode;
        } else if (this.loggedInUser) {
          if (this.loggedInUser.sessionId) {
            this.showNavBar = true;
          }
        }
      });
      if (this.loggedInUser) {
        const name = this.loggedInUser.firstName + ' ' + this.loggedInUser.lastName;
        const initials = name.match(/\b\w/g) || [];
        const initial = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
        this.imageName = initial;
      }
    }
  }

  ngOnInit() {
    if (this._cookieService.get('loggedInUser') != undefined) {
      this.loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'))
    }
    if (this.loggedInUser) {
      this.showNavBar = true;
    }

    if (window.innerWidth <= 992) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('toggle');
    }
  }

  toggleSideBar(event) {
    const body = document.getElementsByTagName('body')[0];
    if (body.classList.contains('toggle')) {
      body.classList.remove('toggle');
    } else {
      body.classList.add('toggle');
    }
  }

  logout() {
    this._cookieService.remove('loggedInUser');
    this._cookieService.remove('roles');
    this._cookieService.remove('Certificate');
    localStorage.removeItem('UserDirectoryFilters');
    localStorage.removeItem('reportFilters');
    this.router.navigate([KRONOS.ROUTERLINKS.LOGIN]);
    this.growlData = {
      severity: 'success', summary: KRONOS.MESSAGES.LOGOUT_SUCCESSFUL, detail: ''
    };
    this._myCommunicationService.emitChange(this.growlData);
  }

  hideSideMenu() {
    $('.sideNavBar').removeClass('width100');
    $('.dataContent').removeClass('widthDataContent');
  }
}
