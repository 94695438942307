import { Injectable } from '@angular/core';
import { KRONOS, STYRIA } from '../../../app-constants';
import { UtilsService } from '../core/utils.service';
import { DataService } from '../core/interceptor.service';
@Injectable()

export class AnalyticsService {

  constructor(private utils: UtilsService, private dataService: DataService) { }

  getTrainingsData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + KRONOS.APIS.GET_TRAINING_ANALYTICS, req);
  }
  getAllOrgLeaves() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_ORG_LEAVES, req);
  }
  getLeaveData(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + KRONOS.APIS.GET_LEAVE_ANALYTICS, req);
  }
  getExperienceCount() {
    const req = {};
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + KRONOS.APIS.GET_UNITWISE_EXPERIENCE_COUNT, req);
  }
  getDepartmentGrowth(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + KRONOS.APIS.GET_DEPARTMENTWISE_GROWTH, req);
  }
  getDateOfJoining(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + KRONOS.APIS.GET_MONTHWISE_DOJ, req);
  }
  getDateOfLeaving(req) {
    return this.dataService.post(KRONOS.BASE + KRONOS.ANALYTICS + KRONOS.APIS.GET_MONTHWISE_DOL, req);
  }

  getLeaveReportFromKronos(req) {
    return this.dataService.get(STYRIA.GATEWAY + STYRIA.INTEGRATION_SERVICE_VER + STYRIA.KRONOS + STYRIA.APIS.INTEGRATION_SERVICE.ORG_LEAVE_REPORT, req.params);
  }

  getLeaveReportFromZoho(req) {
    return this.dataService.get(STYRIA.GATEWAY + STYRIA.INTEGRATION_SERVICE_VER + STYRIA.ZOHO_PEOPLE + STYRIA.APIS.INTEGRATION_SERVICE.ORG_LEAVE_REPORT, req.params);
  }
}
