

import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { DataService } from '../core/interceptor.service';
import { UtilsService } from '../core/utils.service';

@Injectable()
export class NoticeService {

  constructor(private dataService: DataService, private utils: UtilsService) { }

  addMemberOnNoticePeriod(obj) {

   return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.ADD_MEMBER_ON_NOTICE_PERIOD, obj);
  }
  
  updateMemberOnNoticePeriod(obj) {

    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.UPDATE_MEMBER_ON_NOTICE_PERIOD, obj);
  }
  
  deleteMemberOnNoticePeriod(obj) {

    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.DELETE_MEMBER_ON_NOTICE_PERIOD, obj);
  }
  
  getAllMembersOnNoticePeriod(){
    const req = { };
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ALL_MEMBERS_ON_NOTICE_PERIOD,req);
  }

  getMembersOnNoticePeriodById(obj){
    return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_MEMBER_ON_NOTICE_PERIOD_BY_ID,obj);
  }


}
