﻿import { Injectable } from '@angular/core';
import { DataService } from './interceptor.service';
import { KRONOS } from '../../../app-constants';
import { UtilsService, CommunicationService } from '..';
import { Router, ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie';
@Injectable()
export class AuthenticationService {
    sessionId: any;
    sessionObj: any = {};
    growlData: any = {};

    constructor(private dataService: DataService, private _cookieService: CookieService) {
    }

    login(username: string, password: string) {
        const obj = {
            username: username,
            password: password,
            portal: 'HR',
        };
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.LOGIN, obj);
    }

    getRolesByOrgLevel(sessionId: string) {
        return this.dataService.get(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_ROLES_BY_ORG + '/' + sessionId);
    }

    /**
 * gives release version
 */
    getVersion() {
        const req = {};
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.GET_VERSION, req);
    }

    logout() {
        if (this._cookieService.get('loggedInUser') !== undefined) {
            this.sessionId = this._cookieService.get('loggedInUser');
            this.sessionId = JSON.parse(this.sessionId);
            if (this.sessionId.sessionId) {
                const req = {
                    sessionId: this.sessionId.sessionId
                };
                return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.LOGOUT, req);
            }
        }
    }

    forgotPassword(req) {
        return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.FORGOT_PASSWORD, req);
    }

    changepassword(oldpassword: string, newpassword: string, confirmPassword: string) {
        if (this._cookieService.get('loggedInUser') != undefined) {
            const req = {
                oldPass: oldpassword, newPass: newpassword, confirmNewPass: confirmPassword
            };
            return this.dataService.post(KRONOS.BASE + KRONOS.USER + KRONOS.APIS.CHANGE_PASSWORD, req);
        }
    }
}
