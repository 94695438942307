import { Component, OnInit, HostListener } from '@angular/core';
import { GlobalEventsManager } from '../../../helpers/guards';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { AuthenticationService, UtilsService, CommunicationService } from '../../../helpers/services';
import { CookieService } from 'ngx-cookie';
import { KRONOS } from '../../../app-constants';

@Component({
  selector: 'app-sub-navigation',
  templateUrl: './sub-navigation.component.html',
  styleUrls: ['./sub-navigation.component.scss'],
  providers: [AuthenticationService, GlobalEventsManager]
})
export class SubNavigationComponent implements OnInit {
  showSideBar = true;
  loggedInUser: any = [];
  selectedItem: any;
  toggleSideBar: any;
  growlData: any = {};
  tabList: any[] = [

    { 'name': 'Employee', 'link': KRONOS.ROUTERLINKS.USERS, 'icon': 'people' },
    { 'name': 'Leave-Entry', 'link': KRONOS.ROUTERLINKS.LEAVE_ENTRY },
    // { 'name': 'PMS', 'link': KRONOS.ROUTERLINKS.PMS, 'icon': 'network_check' },
    { 'name': 'Travel', 'link': KRONOS.ROUTERLINKS.TRAVEL, 'icon': 'flight_takeoff' },
    { 'name': 'Trainings', 'link': KRONOS.ROUTERLINKS.TRAINING, 'icon': 'school' },
    { 'name': 'Leave', 'link': KRONOS.ROUTERLINKS.LEAVE, 'icon': 'today' },
    { 'name': 'Department', 'link': KRONOS.ROUTERLINKS.GROWTH, 'icon': 'assessment' },
    { 'name': 'Experience', 'link': KRONOS.ROUTERLINKS.EXPERIENCE, 'icon': 'card_travel' },
    { 'name': 'Reports', 'link': KRONOS.ROUTERLINKS.REPORTS, 'icon': 'library_books' },
    { 'name': 'Hiring', 'link': KRONOS.ROUTERLINKS.HIRING, 'icon': 'how_to_reg' },
    { 'name': 'Hiring Tracker', 'link': KRONOS.ROUTERLINKS.TRACKER, 'icon': 'person_pin' },
    { 'name': 'Notice', 'link': KRONOS.ROUTERLINKS.NOTICE, 'icon': 'import_contacts' },
    { 'name': 'Probation', 'link': KRONOS.ROUTERLINKS.PROBATION, 'icon': 'person' },
    // { 'name': 'Activity Challenge', 'link': KRONOS.ROUTERLINKS.CHALLENGE, 'icon': 'transfer_within_a_station' }


  ];
  @HostListener('window:resize', ['$event'])
  onresize(event) {

  }

  constructor(private globalEventsManager: GlobalEventsManager,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private _myCommunicationService: CommunicationService,
    private utils: UtilsService,
    private _cookieService: CookieService) {

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // tslint:disable-next-line:no-unused-expression
        event.url.indexOf(KRONOS.ROUTERLINKS.USERS) > -1 ? this.selectedItem = this.tabList[0] : '';
        // tslint:disable-next-line:no-unused-expression
        // event.url.indexOf(KRONOS.ROUTERLINKS.ORGANIZATIONS) > -1 ? this.selectedItem = this.tabList[2] : '';
        // // tslint:disable-next-line:no-unused-expression
        // event.url.indexOf(KRONOS.ROUTERLINKS.LOGS) > -1 ? this.selectedItem = this.tabList[4] : '';
      }
    });
  }

  ngOnInit() {
    if (this._cookieService.get('loggedInUser') !== undefined) {
      this.loggedInUser = JSON.parse(this._cookieService.get('loggedInUser'));
    }
  }

  listClick(event, newValue) {
    this.selectedItem = newValue;
    this.router.navigate([newValue.link]);
    if (window.innerWidth < 992) {
      const body = document.getElementsByTagName('body')[0];
      body.classList.add('toggle');
    }
  }

  logout() {
    this.authenticationService.logout()
      .subscribe(
        data => {
          if (this.utils.isResponseSuccess(data)) {
            this.router.navigate([KRONOS.ROUTERLINKS.LOGIN]);
            this.growlData = {
              severity: 'success', summary: KRONOS.MESSAGES.LOGOUT_SUCCESSFUL, detail: ''
            };
            this._myCommunicationService.emitChange(this.growlData);
            this._cookieService.remove('loggedInUser');
            this._cookieService.remove('roles');
            this._cookieService.remove('Certificate');
            localStorage.removeItem('UserDirectoryFilters');
            localStorage.removeItem('reportFilters');
          }
        },
        error => {
          this.growlData = {
            severity: 'error', summary: KRONOS.MESSAGES.SOMETHING_WRONG, detail: KRONOS.MESSAGES.TRY_AGAIN
          };
          this.router.navigate([KRONOS.ROUTERLINKS.LOGIN]);
          this._myCommunicationService.emitChange(this.growlData);
        });
  }

}
