import { Injectable } from '@angular/core';
import { KRONOS } from '../../../app-constants';
import { DataService } from '../core/interceptor.service';
import { UtilsService } from '../core/utils.service';

// import { HttpEvent, HttpHandler, HttpRequest, HttpClient } from '@angular/common/http';
// import { RequestOptions, RequestMethod, Headers } from '@angular/http';
// import { Observable } from 'rxjs/Rx';
// import 'rxjs/add/observable/throw'
// import 'rxjs/add/operator/catch';

@Injectable()
export class ChallengeService {

  constructor(private dataService: DataService, private utils: UtilsService) { }
  /**
   * To get all challenge
   */
  getAllChallenge() {
    const req = {
      
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.CHALLENGE + KRONOS.APIS.GET_ALL_CHALLENGES, req);
  }

  /**
   * To add challenge
   * @param  {} obj
   */
  addChallenge(obj) {
      
    return this.dataService.post(KRONOS.BASE + KRONOS.CHALLENGE + KRONOS.APIS.ADD_CHALLENGE, obj);
  }

  /**
   * To update challenge
   * @param  {} obj
   */
  updateChallenge(obj) {
      
    return this.dataService.post(KRONOS.BASE + KRONOS.CHALLENGE + KRONOS.APIS.UPDATE_CHALLENGE, obj);
  }

  /**
   * To delete challenge
   * @param  {Number} id
   */
  deleteChallenge(id) {
    const req = {
      
      challengeId: id
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.CHALLENGE + KRONOS.APIS.DELETE_CHALLENGE, req);
  }

  batchUpload(file) {
    const formData: FormData = new FormData();
    formData.append('file', file);
       
    return this.dataService.post(KRONOS.BASE + KRONOS.CHALLENGE + KRONOS.APIS.FILE_UPLOAD, formData);
  }

  getAllUser() {
    const req = {
      
    };
    return this.dataService.post(KRONOS.BASE + KRONOS.CHALLENGE + KRONOS.APIS.GET_ALL_USER, req);
  }

}
