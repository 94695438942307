import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard, GlobalEventsManager, LoginGuard } from './helpers/guards/index';
import { KRONOS } from './app-constants';

export const routes: Routes = [
  { path: '', loadChildren: 'app/core/core.module#CoreModule', canActivate: [LoginGuard] },
  { path: '', loadChildren: 'app/modules/main.module#MainModule', canActivate: [AuthGuard] }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes),
    CommonModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
