import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AuthenticationService, UtilsService, CommunicationService } from './helpers/services/index';
import { SharedModule } from './modules/shared/shared.module';
import { AuthGuard, LoginGuard } from './helpers/guards';
import { KRONOS } from './app-constants';
import { GrowlModule, DragDropModule, DataTableModule, ConfirmDialogModule, ConfirmationService} from 'primeng/primeng';
import { ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { CookieModule } from 'ngx-cookie';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    CookieModule.forRoot(),
    GrowlModule,
    SharedModule,
    ConfirmDialogModule,
    ReactiveFormsModule,
    DragDropModule,
  ],
  providers: [AuthenticationService, UtilsService, CommunicationService, AuthGuard, LoginGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
